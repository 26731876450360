import React from 'react';
import Footer from './Footer';

function Server() {
	return (
	<p>
		<div className='intro pure-u-2-3'>
		<h3>VOIP</h3>
		<img src='assets/business-businessmen-classroom-communication-267507.jpg' width="100%" />
		<p>Plenty of experiencie using Asterisk/Vicidial and Asterisk/Elastix</p>
		<p>From basic SIP Trunk setup, extensions, dial plans... to more extensive and complex setups like custom AGI/Perl/Python scripts, IVRs, AMD, etc.</p>
		<p>Over 7 years of experience setting up and managing VOIP systems!</p>
		</div>
		<Footer/>
	</p>
	);
}

export default Server;