import React from 'react';
import axios from 'axios';
import { loadReCaptcha  } from 'react-recaptcha-v3';
import { Redirect } from 'react-router-dom';
import ExampleComponent from './ExampleComponent';

class CForm extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
  	name: '',
  	email: '',
  	message: '',
	orderCode: '',
	action: '',
	token: false
	}
	this.formUpdater = this.formUpdater.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
  }
componentDidMount() {
  loadReCaptcha("6LeLQeAUAAAAAJQLhLJJ6zRF9Kss1fm_NdQy57x4");
  this.setState( { token: true });
}
tester = () => {
	console.log("recaptcha loaded");
}
render() {
	if(this.state.action === 'lookup') {
		return <Redirect to={"/Query/"+ this.state.orderCode} />
	} else if(this.state.action === 'neworder') {
		return <Redirect to={"/Quote/"+ this.state.orderCode} />
	} else {
 return(
   <div className="App">
		<div className='pure-u-1 pure-u-md-1-2'>
		<h3>Request a Quote</h3>
	   <form id="contact-form" className="pure-form pure-form-stacked" onSubmit={this.handleSubmit} method="POST">
		<div className="form-group">
			<label htmlFor="name">Name</label>
			<input type="text" name="name" id="name" className="pure-input-2-3" value={this.state.name} onChange={this.formUpdater} />
		</div>
		<div className="form-group">
			<label htmlFor="exampleInputEmail1">Email</label>
			<input type="email"  name="email" id="email" className="pure-input-2-3" aria-describedby="emailHelp" value={this.state.email} onChange={this.formUpdater} />
		</div>
		<div className="form-group">
			<label htmlFor="message">Message</label>
			<textarea className="pure-input-2-3" rows="5"  name="message" id="message" onChange={this.formUpdater} />
		</div>
		<button type="submit" className="pure-button pure-button-primary">Submit</button>
		</form>
    </div>
	<div className='pure-u-1 pure-u-md-1-2'>
		<h3>Already have an service order?</h3>
		<form method='POST'  className="pure-form pure-form-stacked"  id="contact-form" onSubmit={this.handleLookup.bind(this)}>
			<label htmlFor="exampleInputEmail1">Order Code</label> 
			<input type="text" className="pure-input-2-3" aria-describedby="orderCode" value={this.state.orderCode} onChange={this.onOrderCodeChange.bind(this)} /><br />
			<button type='submit' className='pure-button pure-button-primary'>Lookup</button> 
			<ExampleComponent />

		</form>
	</div>
	</div>
	
 );
	}
}
formUpdater(event) {
	this.setState({[event.target.name]: event.target.value});
}

  onOrderCodeChange(event) {
	this.setState({orderCode: event.target.value})
  }

	handleSubmit(event) {
		event.preventDefault();
		if(!this.state.token) return false;
		var bodyFormData = new FormData();
		const that = this;
		bodyFormData.set('name', this.state.name);
		bodyFormData.set('email', this.state.email);
		bodyFormData.set('message', this.state.message);
		axios({
			method: 'post',
			url: 'https://api.sergiomontoya.tech/index.php?action=request',
			data: bodyFormData,
			headers: {'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				//handle success
				that.setState({'orderCode': response.data.orderid, 'action' : 'neworder'});
			})
			.catch(e => {
				//handle error
				console.log(e);
			});

	}
	handleLookup(event) {
		event.preventDefault();
		if(!this.state.token) return false;
		this.setState({ action: 'lookup'});
	}
}

export default CForm;