import React from 'react';
import axios from 'axios';
import './OrderNotes.css';
import OrderNotes from './OrderNotes';
class Query extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orderid: null,
			orderinfo: null,
			completed: false
		};
	};
	

	 componentDidMount() {
	const { match: { params } } = this.props;
	  axios.get(`https://api.sergiomontoya.tech/index.php?getorder=${params.orderId}`)
		.then((order) => {
			{ /* console.log('order', order); */ }
		  this.setState( { orderid: params.orderId, orderinfo: order.data, completed: true });
		  console.log(this.state.orderinfo);
		});
  }

  render() {
	  if(this.state.completed == true) {
		  if(this.state.orderinfo.result == "success") {
			  return (
				<div>
					<h2>Order {this.state.orderinfo.orderinfo.orderid}</h2>
					<p><strong>Status: </strong>{this.state.orderinfo.orderinfo.status}</p>
					<p><strong>Order Created On: </strong>{this.state.orderinfo.orderinfo.createdAt}</p>
					<p><strong>Order Last Updated: </strong>{this.state.orderinfo.orderinfo.updatedAt}</p>
					<div>
					<h3>Order Log</h3>
						{Object.keys(this.state.orderinfo.orderinfo.notes).map((item, i) => (
						  <div className='orderNoteItem' key={i}>
							<div className='orderNoteText'>{ this.state.orderinfo.orderinfo.notes[item].noteText } </div>
							<div className='orderNoteDate'>{ this.state.orderinfo.orderinfo.notes[item].formatedDate }</div>
						  </div>
						))}
					</div>
				</div>
			  );		  
		  }
		  if(this.state.orderinfo.result == "not found") {
			  return (
				  <h2>Order not found</h2>
			  )
		  }
	  }
	  else {
		  return (
				<h4>Searching...</h4>
		  );		  
	  }

  }
}

export default Query;