import React from 'react';
import OrderNotesItem from './OrderNotesItem';
function OrderNotes() {
	return (
		<div>
			<OrderNotesItem />
		</div>
	);
}

export default OrderNotes;