import React from 'react';
import './Footer.css';
import CForm from './CForm';
function Footer() {
	return (
		<div className="footer">
			<CForm /> { /*
			<div className="baseFooter">
				<h4>Contact Me</h4>
				<img className='icon' src='assets/qr.png'/>
			</div>
			*/ }
		</div>
	);
}

export default Footer;