import React from 'react';
import Footer from './Footer';
function Server() {
	return (
	<p>
		<div className='intro pure-u-2-3'>
			<h3>Server Setup & Administration</h3>
			<img src='assets/bandwidth-close-up-computer-connection-1148820.jpg' width="100%"  />
			<p>Almost any service, modification, build or upgrade required to PCs running Windows or Linux should be no problem! Bellow are all version that I experience with and can work on:</p>
			<p><strong>Services:</strong> Install, Repair, Reinstall, Cleanup, Upgrade</p>
			<p><strong>OSs:</strong> Windows Server, Windows NT, Linux OpenSuse, Debian, Ubuntu Server, ViciDial, Asterisk, PFSense, OpnSense, IPFire, etc. </p>
			<p><strong>HDW:</strong> Upgrades, Custom Builds</p>
		</div>
		<Footer/>
	</p>
	);
}

export default Server;