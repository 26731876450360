import React from 'react';

class Quote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			orderid: null,
		};
	};
	

	 componentDidMount() {
	const { match: { params } } = this.props;
		this.setState( { orderid: params.orderId });
	 }
  
  render() {

	  return (
	  <div>
			<h1>Your quote  has been requested!</h1>
			<h3>I will get back to you for follow up on your quote</h3>
			<h2>Quote ID {this.state.orderid}</h2>
		</div>
	  );
  }
}

export default Quote;