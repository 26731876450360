import React from 'react';
import Nav from './Nav';
import Header from './Header';
import Main from './Main';
import PC from './Pc';
import Networking from './Networking';
import Server from './Server';
import Query from './Query';
import Quote from './Quote';
import Dev from './Dev';
import Voip from './VOIP';
import './App.css';
import 'purecss/build/pure-min.css';
import 'purecss/build/grids-min.css';
import 'purecss/build/grids-responsive-min.css';
import './App2.css';
import headerImg from './wallhaven-4yom7k.jpg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
  <Router>
	  <div className="App">
		<Header />
		<div className="navigation"><Nav /></div>
			<Switch>
				<Route path="/" exact component={Main} />
				<Route path="/pc" component={PC} />
				<Route path="/server" component={Server} />
				<Route path="/networking" component={Networking} />
				<Route path="/voip" component={Voip} />
				<Route path="/dev" component={Dev} />
				<Route path="/query/:orderId" component={Query} />
				<Route path="/quote/:orderId" component={Quote} />
			</Switch>
		</div>
		
	</Router>
  );
}

export default App;
