import React from 'react';
import Footer from './Footer';

function Dev() {
	return (
	<p>
		<div className='intro pure-u-2-3'>
			<h3>Software Development</h3>
			<img src='assets/abstract-business-code-coding-276452.jpg' width="100%" />
			<p><strong>Web Languages:</strong> PHP, React/JSX</p>
			<p><strong>Desktop Languages:</strong> C, C++, C#, Java, Python</p>
			<p><strong>Frameworks:</strong> JQuery, Bootstrap, Slim</p>
			<p><strong>Software:</strong> Apache, Node</p>
			<p>Over 15 years of coding experience!</p>
		</div>
		<Footer/>
	</p>
	);
}

export default Dev;