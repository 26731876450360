import React from 'react';
import Footer from './Footer';
import './Main.css';
function Main() {
  return (
  <div className="Main">
  <div className="pure-g center">
	<div className="pure-u-1">
		<h3>About Me</h3>
	</div>
	<div className="pure-u-1 pure-u-md-1-5">
		<img src="assets/photo.jpg" alt="photo" className="photo" />
	</div>
	<div className="pure-u-1 pure-u-md-3-5 intro">
		<div className='subintro'>
		<p>My name is Sergio Montoya, I started using computers since I was 6 years old, learned my first MS-DOS commands and from there on continue to experiment, research and test everything about technology, from taking computers apart, to formating OSs, networking and everything related to digital technology.</p>
		<p>I have always focused in software development writing my first batch scripts at the age of 10 years and Pascal coding at 12. By the age of 15 I started playing around with basic HTML and JS and by 16 started developing web apps using PHP, since then I focused in Web Development and I've been a PHP dev with plenty of other programming languages of experience focusing only in Open Source languages.</p>
		<p>For more info about myself and experience, please visit my <a href='https://www.linkedin.com/in/sergio-montoya-sandoval/'>LinkedIn profile</a>.</p>
		</div>
	</div>
	<div className="pure-u-1-3 pure-u-md-1-5">
		<center><img className='icon' src='assets/qr.png' className='photo' /></center>
	</div>
	<div className="pure-u-1">
		<h2>Services</h2>
	</div>
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/repair.svg'/></p>
		<div className="box1">
			<h3>PC Maintenance</h3>
			<p>Almost any service, modification, build or upgrade required to PCs running Windows or Linux should be no problem! Or even data recovery from faulty or damaged hard drives, depending on health of device.</p>
		</div>
	</div>
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/servers.svg'/></p>
		<div className="box1">
		<h3>Server Administration</h3>
		<p>Most standard requirements for server and services management should be no problem! From installing, upgrading, manual and automated backups and more!</p>
		</div>
	</div>
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/network.svg'/></p>
		<div className="box1">
		<h3>Networking</h3>
		<p>Installation and improvements of networking within your home or office, analysis of WiFi coverege, speed, wired and wireless security and internet monitoring, filtering, security and usage.</p>
		</div>
	</div>	
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/support.svg'/></p>
		<div className="box1">
		<h3>VOIP & ViciDial Management and Training</h3>
		<p>Most of basic and advanced usage, configuration and implementation in Asterisk/ViciDial/FreePBX, even integration with other platforms and/or APIs.</p>
		</div>
	</div>
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/surveillance-video-camera-svgrepo-com.svg'/></p>
		<div className="box1">
		<h3>Security & Survelliance</h3>
		<p>Keep an eye to your home and business!</p>
		</div>
	</div>
	<div className="pure-u-1 pure-u-md-1-2">
		
		<p><img className='icon' src='assets/code.svg'/></p>
		<div className="box1">
		<h3>Software Development</h3>
		<p>With more than 15 years of experience don't hesitate to get your ideas developed!</p>
		</div>
	</div>
		{/* <p>Common software: Services, daemons, apache, nginx, lighttpd, MySQL, MariaDB, PostreSQL<br/>
		OSs: Plenty of Linux Distros, OpnSense, pfSense, ClearOS</p>
		<div className="pure-g">
			<div className="pure-u-1-2 pure-u-md-1-5">
				<img src='assets/nginx-1.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-5">
				<img src='assets/apache-13.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-5">
				<img src='assets/mariadb.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-5">
				<img src='assets/mysql.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-5">
				<img src='assets/linux-tux.svg' />
			</div>
		</div>
	<p>
		<p>Firewall, Security, VLANs, OpnSense, pfSense, File Sharing, NAS, VPNs, DHCP, DNS, SIP, PBX, WIFI a/b/g/n/ac, WIFI APs, NoIP, Proxies</p>
	</p>
	<p>
		<p>Asterisk, ViciDial: User Management, Campaigns, DIDs, Phones/Extensions, Lists, Filters, Scripting, Dial Mix, IVRs, custom extensions and routing, Customizations, Carriers<br/>
		Multi-server/clusters, Replication, custom reports,</p>
		<div className="pure-g">
			<div className="pure-1">
				<img src="assets/Asterisk_logo.svg" />
			</div>
		</div>
	</p>
	<p>
		<p>Website Developement, PHP, JS, Python 2/3, Node, JSX, React<br/>
		Analytics Tools: Google Analytics<br />
		Framworks: jQuery & BootStrap</p>
		<div className="pure-g">
		
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/HTML5_Logo.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/css3.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/logo-javascript.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/bootstrap-4.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/jquery.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/php-1.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/python-4.svg' />
			</div>
			<div className="pure-u-1-2 pure-u-md-1-8">
				<img src='assets/react.svg' />
			</div>
		</div>
*/}
	</div>
	<Footer/>
	</div>
	
  );
}

export default Main;
