import React from 'react';
import {
  Link
} from "react-router-dom";

function Nav() {
  return (
  <div className="Nav pure-menu-horizontal pure-menu pure-menu-scrollable">
  <ul className="pure-menu-list">
<li className="pure-menu-item">About Me</li> <li className="pure-menu-item"><Link to="/" className="pure-menu-link">Home</Link></li> <li className="pure-menu-item"><Link to="/pc" className="pure-menu-link">PC Maintenance</Link></li> <li className="pure-menu-item"><Link to="/server" className="pure-menu-link">Server Admin</Link></li> <li className="pure-menu-item"><Link to="/networking" className="pure-menu-link">Networking</Link></li> <li className="pure-menu-item"><Link to="/voip" className="pure-menu-link">VOIP</Link></li> <li className="pure-menu-item"><Link to="/dev" className="pure-menu-link">Software Dev</Link></li>
</ul>
	</div>
  );
}

export default Nav;
