import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3'

class ExampleComponent extends Component {

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }
  render() {
    return (
      <div>
        <ReCaptcha
            
            sitekey="6LeLQeAUAAAAAJQLhLJJ6zRF9Kss1fm_NdQy57x4"
            action='action_name'

        />
      </div>
    );
  };
};

export default ExampleComponent;