import React from 'react';
import Footer from './Footer';

function PC() {
	return (
	<p>
		<div className='intro pure-u-2-3'>
		<h3>PC Maintenance</h3>
		<img src='assets/gamingpc.jpg' width="100%" />
		<p>Almost any service, modification, build or upgrade required to PCs running Windows or Linux should be no problem! Bellow are all version that I experience with and can work on:</p>
		<p><strong>Services:</strong> Install, Repair, Reinstall, Cleanup, Upgrade, Data Recovery from Hard drives.<br/>
		<strong>OSs:</strong> Windows 3.1/9x/ME/2000/XP/Vista/7/8/8.1/10, DOS<br/>
		<strong>Office:</strong> Office 97-2019m LibreOffice, OpenOffice<br/>
		<strong>SW:</strong> Antivirus, AntiMalware, Other Software Install<br/>
		<strong>HDW:</strong> Upgrades, Custom Builds<br/>
		<strong>Recovery FSs:</strong> NTFS, EXFAT, FAT, HPFS, etc.</p>
		</div>
		<Footer/>
	</p>
	);
}

export default PC;