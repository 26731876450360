import React from 'react';
import Footer from './Footer';

function Networking() {
	return (
	<p>
		<div className='intro pure-u-2-3'>
		<h3>Networking</h3>
		<img src='assets/white-and-blue-cables-2881229.jpg' width="100%" />
		<p><strong>LAN:</strong> Cat5e/6 wiring implementations through ceiling, floor, walls.</p>
		<p><strong>WLAN:</strong> Access Points, extenders, etc., all wireless protocols, ie: 802.11a/b/g/n/ac.</p>
		<p>WiFi coverage analysis</p>
		</div>
		<Footer/>
	</p>
	);
}

export default Networking;