import React from 'react';
import './Header.css';
function Header() {
return (
<div className="pure-u-1 headerBanner">
<p>
Sergio Montoya IT Services
</p>
</div>
);
}

export default Header;